import React, { useEffect, Fragment, useState } from "react"
import { scrollToElement } from "../../../utility/utils"
import EntryIllustration from "../../../../assets/img/overdraft-calculator-entry-illustration.inline.svg"
import BadgesEntryFold from "../general/badgesEntryFold"
import CTALoanEligiblity from "../general/ctaLoanEligiblity"
import * as eventLogger from '../../../../util/eventLogger';
import { LOOKALIKE_AUDIENCE, MEDIUM, UTM_SOURCE, CHANNEL } from "../../../../util/eventLogger/segment/constants/eventProperties"
import { CREDIT_PAGE_VIEWED } from "../../../../util/eventLogger/segment/constants/eventTags"

const OverDraftCalculatorLanding = () => {
  const [queryParameters, setQueryParameters] = useState(null);

  const entryContent = {
    title: `Can you take a Kuda Overdraft?`,
    subtitle: "Check your eligibility for a Kuda Overdraft in a few steps.",
    illustration: <EntryIllustration />,
  }


  useEffect(() => {

    scrollToElement();
    window.addEventListener("scroll", function () {
      scrollToElement();
    });

  }, [])


  const isOverdraftPageTracked = () => {
    const isTracked = sessionStorage.getItem("LOAN_PAGE_VIEWED") === "tracked";


    const path = window.location.href;
    setQueryParameters(path.split('?')[1]);

    if (
      !isTracked && path.split('?').length > 1
    ) {

      const queryParameters = new URLSearchParams(window.location.search);

      /**-----------------SEGMENT---------------------------- */
      const segmentData = {
        [LOOKALIKE_AUDIENCE]: queryParameters.get("lookalike_audience"),
        [UTM_SOURCE]: queryParameters.get('utm_source'),
        [MEDIUM]: queryParameters.get('utm_medium'),
        [CHANNEL]: queryParameters.get('utm_campaign')
      };
      eventLogger.pushEventGlobally(CREDIT_PAGE_VIEWED, segmentData);
      /**---------------------------------------------------------------- */
      sessionStorage.setItem("CREDIT_PAGE_VIEWED", "tracked");
    }
  }

  useEffect(() => {
    isOverdraftPageTracked();
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        showEmployerBtn={true}
        btnTitle="Check Eligibility"
        headerLink={queryParameters !== null ? `/en-ng/overdraft-eligibility-form/?${queryParameters}` : `/en-ng/overdraft-eligibility-form/`}
      />

      <CTALoanEligiblity />
    </Fragment>
  )
}

export default OverDraftCalculatorLanding
