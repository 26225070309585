import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import OverDraftCalculatorLanding from "../../components/body/pages/overdraft-eligibility"

const OverdraftsPage = () => (
  <Layout>
    <SEO
      title="Kuda | Overdraft Eligibility | See if you are eligible for an Overdraft with Kuda"
      noIndex={true}
    />
    <OverDraftCalculatorLanding />
  </Layout>
)

export default OverdraftsPage
